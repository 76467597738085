@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
.folder-list {
    list-style: none;
    margin-left: -35px;
    margin-right: 5px;
    border-right: 1px dashed black;
    padding-right: 10px;
}

.folder-list li {
    text-align: center;
    padding: 0;
    border-radius: 15px;
    background: #720056;
    margin-bottom: 10px;
}

.folder-list a {
    color: peachpuff;
    text-decoration: none;
}

.folder-list li:hover {
    background: #00C90D;
}

.folder-list li a:hover {
    color: #720056;
}


#new-folder {
    background: coral;
}

#new-folder a {
    color: #720056;
    padding: 11%
}

#new-folder:hover {
    background: yellow;
}

#new-folder a:hover {
    color: coral;
}

.main-sidebar a {
    text-decoration: none;
    display: block;
    height: 60px;
    width: 100%;
    padding: 17%;
}
.folder-list .current {
    background: coral;
    color: white;
    border-radius: 15px;
    border-bottom: 2px solid black;
}

.folder-list .current a {
    color: white;
}

.folder-list a {
    display: block;
    height: 60px;
    width: 100%;
    padding: 17%;
}
.note-folder button {
    background: teal;
    color: white;
    font-size: 20px;
    margin-left: 17%;
    height: 40px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 50px;
}

.note-folder button:hover {
    background: yellow;
    color: coral;
    cursor: pointer;
}

.note-folder .folder-name {
    color: white;
    background: coral;
    border-radius: 15px;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    margin-right: 10px;
    margin-left: 10px;
    padding: 5px;
    border-bottom: 2px solid black;
}
.AddFolder {
  color: #dedede;
}

.AddFolder h2 {
  margin-left: 16px;
}
.AddNote {
  color: #dedede;
}

.AddNote h2 {
  margin-left: 16px;
}
* {
    box-sizing: border-box;
}

body {
    background: ivory;
}

header {
    text-align: center;
    /* border: 2px dashed black; */
    font-family: Georgia, 'Times New Roman', Times, serif
}

header a {
    text-decoration: none;
    color: #720056;
    letter-spacing: 5px;
    font-family: 'Nunito';
    font-size: 40px;
    border-bottom: 5px double #720056;
}

.app-body {
    display: flex;
}

.sidebar {
    width: 25%;
    /* border: 2px solid green; */
}

.main {
    width: 75%;
    /* border: 2px solid red; */
}

button:hover {
    cursor: pointer
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
