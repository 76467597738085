.note-folder button {
    background: teal;
    color: white;
    font-size: 20px;
    margin-left: 17%;
    height: 40px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 50px;
}

.note-folder button:hover {
    background: yellow;
    color: coral;
    cursor: pointer;
}

.note-folder .folder-name {
    color: white;
    background: coral;
    border-radius: 15px;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    margin-right: 10px;
    margin-left: 10px;
    padding: 5px;
    border-bottom: 2px solid black;
}